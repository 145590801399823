import Axios from "axios";
import { API_URL } from "../consts";
export interface PlaceDto {
  address: string;
  categoryId: string;
  locationId: string;
  name: string;
  emails: string[];
  phoneNumbers: string[];
  urls: string[];
  description: string;
  deliveryMethod: string;
  deliveryTime: string;
  acceptsCash: boolean;
  acceptsMB: boolean;
  acceptsMBWay: boolean;
  allowsCredit: boolean;
}

export const sendJoinEmail = (place: PlaceDto) =>
  Axios.post(`${API_URL}/places/join`, place);
