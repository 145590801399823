import Axios from "axios";
import { API_URL } from "../../consts";
import { useState, useEffect } from "react";

export interface Place {
  id: string;
  name: string;
  emails: string[];
  phoneNumbers: string[];
  urls: string[];
  latitude: number;
  longitude: number;
  address: string;
  description: string;
  deliveryMethod: string;
  deliveryTime: string;
  acceptsCash: boolean;
  acceptsMB: boolean;
  acceptsMBWay: boolean;
  allowsCredit: boolean;
  category: {
    id: string;
    name: string;
    color: string;
  };
  location: { name: string };
}

export interface GetPlacesDto {
  categoryFriendlyId?: string;
  locationFriendlyId?: string;
}

type Hook = (dto: GetPlacesDto) => [Place[], boolean, boolean];

const requestPlaces = (dto: GetPlacesDto) =>
  Axios.get(`${API_URL}/places`, { params: dto });

export const usePlaces: Hook = (dto = {}) => {
  const [data, setData] = useState<Place[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data }: { data: Place[] } = await requestPlaces(dto);
        setData(data);
      } catch (err) {
        setData([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dto]);

  return [data, isLoading, isError];
};
