import React from "react";
import { makeStyles } from "@material-ui/styles";
import CategoryItem from "./CategoryItem";
import { useCurrentSize } from "../../hooks/useCurrentSize";
import { Category } from "../../hooks/category/useCategories";

const useStyles = makeStyles({
  list: ({ vw }: { vw: number }) => ({
    display: "grid",
    gridTemplateColumns: `repeat(auto-fill,minmax(${
      10 * vw < 100 ? 100 : 10 * vw
    }px, 1fr))`,
    gridAutoRows: "1fr",
    gap: "3rem",
    "&::before": {
      content: "''",
      width: 0,
      paddingBottom: "100%",
      gridRow: "1 / 1",
      gridColumn: "1 / 1",
    },
    "& > *:first-child": {
      gridRow: "1 / 1",
      gridColumn: "1 / 1",
    },
  }),
});

export interface CategoryListProps {
  categories: Category[];
}

function CategoryList({ categories }: CategoryListProps) {
  const { width } = useCurrentSize();
  const classes = useStyles({ vw: width / 100 });

  return (
    <div className={classes.list}>
      {categories
        .filter((category) => category.nPlaces > 0 || category.name === "Geral")
        .map((category, i) => (
          <CategoryItem key={i.toString()} {...category} />
        ))}
    </div>
  );
}

export default CategoryList;
