import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Button } from "antd";

const useStyles = makeStyles({
  backBtn: {
    position: "fixed",
    bottom: 16,
    right: 12,
    display: "-webkit-flex",
    alignItems: "center",
    justifyContent: "center",
    height: "64px !important",
    width: "64px !important",
    zIndex: 1000,
  },
  icon: {
    height: "48px",
    width: "48px",
    margin: "auto",
  },
  "@media (min-width: 740px)": {
    backBtn: {
      bottom: 96,
    },
  },
});
function BackButton({ onClick }: { onClick: () => void }) {
  const classes = useStyles();

  return (
    <Button
      size="large"
      onClick={onClick}
      shape="circle"
      className={classes.backBtn}
      icon={
        <img
          className={classes.icon}
          alt="back"
          src={require("../../common_assets/keyboard_arrow_left-24px.svg")}
        />
      }
    >
      {/* Voltar */}
    </Button>
  );
}

export default BackButton;
