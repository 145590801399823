import ReactDOM from "react-dom";
import { Variants } from "framer-motion";

export const measureElement = (element: any) => {
  const DOMNode = ReactDOM.findDOMNode(element);
  if (!DOMNode) {
    return { width: 0, height: 0 };
  }
  return {
    width: (DOMNode as any).offsetWidth,
    height: (DOMNode as any).offsetHeight,
  };
};

export const GenericPageTransition: Variants = {
  exit: { opacity: 0, type: "tween" },
  enter: { opacity: 1, type: "tween" },
};

export const theme = {
  strongGrey: "#6E6E6E",
  lightGrey: "#ECECEC",
  footerBackground: "#A9A9A9",
};

export const GOOGLE_MAPS_KEY = "AIzaSyDokekBOBfErPswvvmQNckIxkGq6b3WaOI";
