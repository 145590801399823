import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Map, Marker, Polygon } from "react-leaflet";
import classNames from "classnames";
import { GenericPageTransition, GOOGLE_MAPS_KEY } from "../../Utils";
import { marker } from "./utils";
import { motion } from "framer-motion";
import { LatLngExpression } from "leaflet";
import { FeatureBadge } from "../../components/FeatureBadge";
import { Place } from "../../hooks/places/usePlaces";
import ContactZone from "./ContactZone";
import InfoZone from "./InfoZone";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { getCentroid2 } from "./PlaceInfo";

const useStyles = makeStyles({
  page: {
    display: "-webkit-flex",
    padding: "3rem 10%",
    flexDirection: "column",
    flex: 1,
    flexGrow: 1,
  },
  row: {
    display: "-webkit-flex",
    flexWrap: "wrap",
    "&:last-child": {
      flexGrow: 1,
      flex: 1,
      marginTop: "2rem",
    },
  },
  column: {
    display: "-webkit-flex",
    flexDirection: "column",
  },
  map: {
    height: 325,
  },
  firstColumn: {
    flex: 0.4,
    marginRight: "2rem",
  },
  secondColumn: {
    flex: 0.6,
    marginLeft: "2rem",
  },
  attributeZone: {
    display: "-webkit-flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
});

export interface PlaceInfoProps {
  place: Place;
  poli?: number[][];
}

function PlaceInfoDesktop({ place, poli }: PlaceInfoProps) {
  const classes = useStyles();

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
      className={classes.page}
    >
      <div className={classes.row}>
        <Map
          center={
            place.latitude && place.longitude
              ? [place.latitude, place.longitude]
              : getCentroid2((poli as unknown) as number[][])
          }
          zoom={11}
          className={classNames(classes.map, classes.firstColumn)}
        >
          <ReactLeafletGoogleLayer
            googleMapsLoaderConf={{ KEY: GOOGLE_MAPS_KEY }}
            type="hybrid"
          />
          {poli && (
            <Polygon positions={[(poli as unknown) as LatLngExpression[]]} />
          )}
          {place.latitude && place.longitude && (
            <Marker
              position={[place.latitude, place.longitude]}
              icon={marker}
            />
          )}
        </Map>
        <InfoZone
          placeDeliveryMethod={place.deliveryMethod}
          placeDeliveryTime={place.deliveryTime}
          placeDescription={place.description}
          placeName={place.name}
          categoryId={place.category.id}
          categoryName={place.category.name}
          className={classes.secondColumn}
        />
      </div>
      <div className={classes.row}>
        <ContactZone
          urls={place.urls}
          className={classes.firstColumn}
          emails={place.emails}
          phoneNumbers={place.phoneNumbers}
          address={place.address}
        />
        <div className={classes.secondColumn}>
          <div className={classNames(classes.row, classes.attributeZone)}>
            {place.acceptsCash && <FeatureBadge variant="acceptsCash" />}
            {place.acceptsMB && <FeatureBadge variant="acceptsMB" />}
            {place.acceptsMBWay && <FeatureBadge variant="acceptsMBWay" />}
            {place.allowsCredit && <FeatureBadge variant="allowsCredit" />}
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default PlaceInfoDesktop;
