import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";

interface StyleProps {
  color: string;
}

const useStyles = makeStyles({
  categoryContainer: ({ color }: StyleProps) => ({
    flexDirection: "column",
    display: "-webkit-flex",
    alignItems: "center",
    border: `1px ${color} solid`,
    overflow: "hidden",
    borderRadius: 5,
    justifyContent: "space-around",
    padding: "0.5rem",
    cursor: "pointer"
  }),
  title: ({ color }: StyleProps) => ({
    textAlign: "center",
    margin: 0,
    textTransform: "uppercase",
    fontWeight: "bold",
    color
  }),
  image: {
    maxWidth: "40%"
  }
});

export interface CategoryItemProps {
  name: string;
  image: string;
  friendlyId: string;
  color: string;
}

function CategoryItem({ name, image, friendlyId, color }: CategoryItemProps) {
  const classes = useStyles({ color });
  const history = useHistory();

  return (
    <div
      className={classes.categoryContainer}
      onClick={() => {
        history.push(`/places/${name === "Geral" ? "geral" : friendlyId}`);
      }}
    >
      <img src={image} alt="place" className={classes.image} />
      <p className={classes.title}>{name}</p>
    </div>
  );
}

export default React.memo(CategoryItem);
