import React from "react";
import { makeStyles } from "@material-ui/styles";
import { GenericPageTransition, theme } from "../../Utils";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  container: {
    display: "-webkit-flex",
    backgroundColor: theme.footerBackground,
    flex: 0,
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 64,
    flexWrap: "wrap"
  },
  leftZone: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  imageContainer: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    margin: "1rem 2rem"
  },
  text: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    flexGrow: 1,
    "& > *": {
      color: "white",
      margin: 0
    }
  },
  montemorLogo: {
    height: 48
  },
  logoLink: {},
  ensoLogo: {
    maxHeight: 24,
    margin: "1rem 0",
    marginRight: "2rem"
  },
  joinLink: { color: "white", minWidth: 150, textAlign: "center" },
  "@media (max-width: 656px)": {
    joinLink: { marginLeft: "calc(50% - 75px)", marginRight: "unset" }
  },
  "@media (max-width: 752px)": {
    logoLink: { marginLeft: "calc(50% - 32.36px)", marginRight: "unset" }
  },
  "@media (max-width: 420px)": {
    text: { fontSize: 10 },
    joinLink: { marginTop: 10 }
  }
});

function Footer() {
  const classes = useStyles();

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
      className={classes.container}
    >
      <div className={classes.leftZone}>
        <div className={classes.imageContainer}>
          <a
            href="https://cm-montemorvelho.pt/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={classes.montemorLogo}
              src={require("../../common_assets/cmmvelho_white.png")}
              alt="montemor-logo"
            />
          </a>
        </div>
        <div className={classes.text}>
          <p>Praça da República, 3140-258 Montemor-o-Velho</p>
          <a href="mailto:compracadentro@cm-montemorvelho.pt">
            compracadentro@cm-montemorvelho.pt
          </a>
        </div>
        <Link to="/aderir" className={classes.joinLink}>
          Aderir
        </Link>
      </div>
      <a
        className={classes.logoLink}
        href="https://enso-origins.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={require("../../common_assets/EnsoOrigins_logo_mono_neg_rgb.png")}
          alt="enso-logo"
          className={classes.ensoLogo}
        />
      </a>
    </motion.div>
  );
}

export default Footer;
