import Axios from "axios";
import { API_URL } from "../../consts";
import { useState, useEffect } from "react";

interface Location {
  id: string;
  name: string;
  friendlyId: string;
}
type Hook = () => [Location[], boolean, boolean];

const requestLocations = () => Axios.get(`${API_URL}/places/locations`);

export const useLocations: Hook = () => {
  const [data, setData] = useState<Location[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data }: { data: Location[] } = await requestLocations();
        setData(data);
      } catch (err) {
        setData([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return [data, isLoading, isError];
};
