import Axios from "axios";
import { API_URL } from "../../consts";
import { useState, useEffect } from "react";

export interface Category extends CategoryDto {
  image: string;
}

interface CategoryDto {
  id: string;
  name: string;
  color: string;
  friendlyId: string;
  nPlaces: number;
}

type Hook = () => [Category[], boolean, boolean];

const requestCategories = () => Axios.get(`${API_URL}/places/categories`);

export const getCategoryImageUrl = (id: string) =>
  `${API_URL}/places/category/${id}`;

export const useCategories: Hook = () => {
  const [data, setData] = useState<Category[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data }: { data: CategoryDto[] } = await requestCategories();
        setData(
          data.map((item) => ({
            ...item,
            image: getCategoryImageUrl(item.id),
          }))
        );
      } catch (err) {
        setData([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return [data, isLoading, isError];
};
