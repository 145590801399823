import React from "react";
import { Place } from "../../hooks/places/usePlaces";
import { makeStyles } from "@material-ui/styles";
import { getCategoryImageUrl } from "../../hooks/category/useCategories";
import { useHistory } from "react-router-dom";
import FeatureBadge from "../FeatureBadge/FeatureBadge";

const useStyles = makeStyles({
  container: ({ color }: { color: string }) => ({
    display: "-webkit-flex",
    justifyContent: "space-between",
    border: `1px ${color} solid`,
    borderRadius: 10,
    padding: "0 2rem",
    flexWrap: "wrap",
    margin: "1rem 0",
    cursor: "pointer",
  }),
  infoZone: {
    display: "-webkit-flex",
    alignItems: "center",
    minWidth: 200,
    flexGrow: 1,
    margin: "1rem 0",
  },
  textZone: {
    display: "-webkit-flex",
    flexDirection: "column",
  },
  title: {
    textTransform: "uppercase",
    wordBreak: "break-word",
    fontWeight: "bold",
  },
  subtitle: {
    wordBreak: "break-word",
    textTransform: "uppercase",
    margin: 0,
  },
  categoryImage: {
    maxHeight: "5rem",
    height: "15vmin",
    marginRight: "2rem",
  },
  attributeZone: {
    display: "-webkit-flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexWrap: "wrap",
    margin: "1rem 0",
  },
  "@media (max-width: 425px)": {
    categoryImage: {
      display: "none",
    },
  },
});
interface PlaceItemProps {
  place: Place;
}

function PlaceItem({ place }: PlaceItemProps) {
  const history = useHistory();
  const classes = useStyles({ color: place.category.color });
  return (
    <div
      className={classes.container}
      onClick={() => {
        history.push(`/place/${place.id}`);
      }}
    >
      <div className={classes.infoZone}>
        <img
          className={classes.categoryImage}
          src={getCategoryImageUrl(place.category.id)}
          alt={place.category.name}
        />
        <div className={classes.textZone}>
          <h3 className={classes.title}>{place.name}</h3>
          <p className={classes.subtitle}>{place.location.name}</p>
        </div>
      </div>
      <div className={classes.attributeZone}>
        {place.acceptsCash && <FeatureBadge variant="acceptsCash" />}
        {place.acceptsMB && <FeatureBadge variant="acceptsMB" />}
        {place.acceptsMBWay && <FeatureBadge variant="acceptsMBWay" />}
        {place.allowsCredit && <FeatureBadge variant="allowsCredit" />}
      </div>
    </div>
  );
}

export default PlaceItem;
