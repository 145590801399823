import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Input,
  Select,
  Row,
  Form,
  Col,
  Grid,
  Checkbox,
  Button,
  message,
  InputNumber,
} from "antd";
import { useLocations } from "../../hooks/locations/useLocations";
import { useCategories } from "../../hooks/category/useCategories";
import { Loader } from "../../components/Loader";
import { Error } from "../../components/Error";
import { Rule } from "antd/lib/form";
import { sendJoinEmail, PlaceDto } from "../../hooks/sendJoinEmail";
import { useHistory } from "react-router-dom";

const { useBreakpoint } = Grid;

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    flexGrow: 1,
    padding: "1rem 10%",
  },
  row: {
    display: "flex",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
  },
  checkboxContainer: ({ isXs }: { isXs: boolean }) => ({
    height: "100%",
    paddingTop: isXs ? undefined : 40,
  }),
  numberInput: { width: "100%" },
});

const EmailRule: Rule = {
  type: "email",
  message: "Insira um email válido.",
};

const URLRule: Rule = {
  type: "url",
  message: "Insira um site válido.",
};

const PhoneRule: Rule = {
  type: "string",
  validator: (_, value) => {
    if (!value || !value.toString().trim()) return Promise.resolve();
    if (new RegExp("^\\d{9}$").test(value)) return Promise.resolve();
    else return Promise.reject("O número de telefone deve ter 9 dìgitos");
  },
};

function Join() {
  const [locations, areLocationsLoading, locationsHaveError] = useLocations();
  const [
    categories,
    areCategoriesLoading,
    categoriesHaveError,
  ] = useCategories();

  const breakpoints = useBreakpoint();
  const classes = useStyles({ isXs: !!breakpoints.xs });

  const [acceptsCash, setAcceptsCash] = useState<boolean>(false);
  const [acceptsMB, setAcceptsMB] = useState<boolean>(false);
  const [acceptsMBWay, setAcceptsMBWay] = useState<boolean>(false);
  const [allowsCredit, setAllowsCredit] = useState<boolean>(false);

  const history = useHistory();

  if (areLocationsLoading || areCategoriesLoading) {
    return <Loader />;
  }

  if (locationsHaveError || categoriesHaveError) return <Error />;

  const sendEmailToJoin = (values: any) => {
    console.log(values);

    const dto: PlaceDto = {
      name: values.name,
      address: values.address,
      description: values.description,
      deliveryMethod: values.deliveryMethod,
      deliveryTime: values.deliveryTime,
      acceptsCash,
      acceptsMB,
      acceptsMBWay,
      allowsCredit,
      categoryId: values.categoryId,
      locationId: values.locationId,
      emails: [0, 1, 2, 3]
        .map((index) => values["email-" + index])
        .filter((email) => email && email.trim()),
      phoneNumbers: [0, 1, 2, 3]
        .map((index) =>
          values["phone-" + index] ? values["phone-" + index].toString() : ""
        )
        .filter((number) => number && number.trim()),
      urls: [0, 1, 2, 3]
        .map((index) => values["url-" + index])
        .filter((email) => email && email.trim()),
    };

    sendJoinEmail(dto)
      .then(() => {
        message.success("A sua sugestão foi enviada com sucesso!");
        history.replace("/");
      })
      .catch((err) => {
        message.error(
          "Ocorreu um erro na submissão da sua sugestão. Se o problema persistir contacte o responsável pelo site."
        );
        console.log(err);
      });
  };

  return (
    <div className={classes.container}>
      <Form onFinish={sendEmailToJoin} name="join" scrollToFirstError>
        <Row gutter={[32, 16]}>
          <Col xs={24} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              label="Nome do estabelecimento"
              name="name"
              rules={[
                {
                  type: "string",
                  required: true,
                  message:
                    "É necessário especificar o nome dos seu estabelecimento",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              label="Categoria"
              name="categoryId"
              rules={[
                {
                  required: true,
                  message:
                    "É necessário especificar uma categoria para o seu estabelecimento",
                },
              ]}
            >
              <Select>
                {categories
                  .filter((category) => category.name !== "Geral")
                  .map((category) => (
                    <Select.Option key={category.id} value={category.id}>
                      {category.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message:
                    "É necessário especificar uma freguesia para o seu estabelecimento",
                },
              ]}
              name="locationId"
              label="Freguesia"
            >
              <Select>
                {locations.map((location) => (
                  <Select.Option key={location.id} value={location.id}>
                    {location.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[32, 16]}>
          <Col xs={24} md={16}>
            <Form.Item
              labelCol={{ span: 24 }}
              label="Descrição"
              name="description"
              rules={[
                {
                  required: true,
                  message:
                    "É necessário especificar uma descrição para o seu estabelecimento",
                },
              ]}
            >
              <Input.TextArea></Input.TextArea>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Row gutter={[0, 12]} className={classes.checkboxContainer}>
              <Col span={12}>
                <Checkbox
                  value={acceptsCash}
                  onChange={(e) => setAcceptsCash(e.target.checked)}
                >
                  Dinheiro
                </Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox
                  value={acceptsMB}
                  onChange={(e) => setAcceptsMB(e.target.checked)}
                >
                  Multibanco
                </Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox
                  value={acceptsMBWay}
                  onChange={(e) => setAcceptsMBWay(e.target.checked)}
                >
                  MBWay
                </Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox
                  value={allowsCredit}
                  onChange={(e) => setAllowsCredit(e.target.checked)}
                >
                  Crédito
                </Checkbox>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[32, 16]}>
          <Col xs={24} sm={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              rules={[
                {
                  type: "string",
                  required: true,
                  message: "É necessário especificar a forma de entrega.",
                },
              ]}
              name="deliveryMethod"
              label="Forma de entrega"
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              rules={[
                {
                  type: "string",
                  required: true,
                  message: "É necessário especificar a forma de entrega.",
                },
              ]}
              name="deliveryTime"
              labelCol={{ span: 24 }}
              label="Tempo de entrega"
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="email-0"
              label="Email #1"
              rules={[EmailRule]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              name="email-1"
              rules={[EmailRule]}
              labelCol={{ span: 24 }}
              label="Email #2"
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              name="email-2"
              rules={[EmailRule]}
              labelCol={{ span: 24 }}
              label="Email #3"
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              name="email-3"
              rules={[EmailRule]}
              labelCol={{ span: 24 }}
              label="Email #4"
            >
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[32, 16]}>
          <Col xs={24} sm={16}>
            <Form.Item
              rules={[
                {
                  type: "string",
                  required: true,
                  message: "O endereço é obrigatório",
                },
              ]}
              name="address"
              labelCol={{ span: 24 }}
              label="Endereço"
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              name="phone-0"
              labelCol={{ span: 24 }}
              rules={[PhoneRule]}
              label="Telefone #1"
            >
              <InputNumber className={classes.numberInput} size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              name="phone-1"
              rules={[PhoneRule]}
              labelCol={{ span: 24 }}
              label="Telefone #2"
            >
              <InputNumber className={classes.numberInput} size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              name="phone-2"
              rules={[PhoneRule]}
              labelCol={{ span: 24 }}
              label="Telefone #3"
            >
              <InputNumber className={classes.numberInput} size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              name="phone-3"
              rules={[PhoneRule]}
              labelCol={{ span: 24 }}
              label="Telefone #4"
            >
              <InputNumber className={classes.numberInput} size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[32, 16]}>
          <Col xs={24} sm={8}>
            <Form.Item
              name="url-0"
              rules={[URLRule]}
              labelCol={{ span: 24 }}
              label="Site #1"
            >
              <Input className={classes.numberInput} size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              name="url-1"
              rules={[URLRule]}
              labelCol={{ span: 24 }}
              label="Site #2"
            >
              <Input className={classes.numberInput} size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              name="url-2"
              rules={[URLRule]}
              labelCol={{ span: 24 }}
              label="Site #3"
            >
              <Input className={classes.numberInput} size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              name="url-3"
              rules={[URLRule]}
              labelCol={{ span: 24 }}
              label="Site #4"
            >
              <Input className={classes.numberInput} size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item
            style={{ marginLeft: "auto" }}
            wrapperCol={{ xs: 24, sm: 12 }}
          >
            <Button type="primary" htmlType="submit">
              Submeter
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
}

export default Join;
