import React from "react";
import { makeStyles } from "@material-ui/styles";
import { PlaceInfoProps } from "./PlaceInfoDesktop";
import { Map, Marker, Polygon } from "react-leaflet";
import classNames from "classnames";
import { marker } from "./utils";
import { LatLngExpression } from "leaflet";
import InfoZone from "./InfoZone";
import { FeatureBadge } from "../../components/FeatureBadge";
import ContactZone from "./ContactZone";
import { GenericPageTransition, GOOGLE_MAPS_KEY } from "../../Utils";
import { motion } from "framer-motion";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { getCentroid2 } from "./PlaceInfo";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    flexGrow: 1,
  },
  map: {
    minHeight: 150,
  },
  paddedContent: {
    padding: "1rem 5%",
  },
  attributeZone: {
    display: "-webkit-flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
});
function PlaceInfoMobile({ place, poli }: PlaceInfoProps) {
  const classes = useStyles();

  return (
    <motion.div
      className={classes.container}
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
    >
      <Map
        dragging={false}
        tap={false}
        center={
          place.latitude && place.longitude
            ? [place.latitude, place.longitude]
            : getCentroid2((poli as unknown) as number[][])
        }
        zoom={11}
        className={classes.map}
      >
        <ReactLeafletGoogleLayer
          googleMapsLoaderConf={{ KEY: GOOGLE_MAPS_KEY }}
          type="hybrid"
        />
        {poli && (
          <Polygon positions={(poli as unknown) as LatLngExpression[]} />
        )}
        {place.latitude && place.longitude && (
          <Marker position={[place.latitude, place.longitude]} icon={marker} />
        )}
      </Map>
      <InfoZone
        placeDeliveryMethod={place.deliveryMethod}
        placeDeliveryTime={place.deliveryTime}
        placeDescription={place.description}
        placeName={place.name}
        categoryId={place.category.id}
        categoryName={place.category.name}
        className={classes.paddedContent}
      />
      <div className={classNames(classes.attributeZone, classes.paddedContent)}>
        {place.acceptsCash && <FeatureBadge variant="acceptsCash" />}
        {place.acceptsMB && <FeatureBadge variant="acceptsMB" />}
        {place.acceptsMBWay && <FeatureBadge variant="acceptsMBWay" />}
        {place.allowsCredit && <FeatureBadge variant="allowsCredit" />}
      </div>
      <ContactZone
        urls={place.urls}
        className={classes.paddedContent}
        emails={place.emails}
        phoneNumbers={place.phoneNumbers}
        address={place.address}
      />
    </motion.div>
  );
}

export default PlaceInfoMobile;
