import React from "react";
import { CategoryList } from "../../components/CategoryList";
import { useCategories } from "../../hooks/category/useCategories";
import { makeStyles } from "@material-ui/styles";
import { Loader } from "../../components/Loader";
import { Error } from "../../components/Error";

const useStyles = makeStyles({
  page: {
    display: "-webkit-flex",
    flexDirection: "column",
    flex: 1,
    flexGrow: 1,
    justifyContent: "center",
    padding: "1rem 10%"
  }
});

function Home() {
  const [categories, isLoading, hasError] = useCategories();
  const classes = useStyles();

  if (isLoading) return <Loader />;

  if (hasError) return <Error />;

  return (
    <div className={classes.page}>
      <CategoryList categories={categories} />
    </div>
  );
}

export default Home;
