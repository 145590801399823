import Axios from "axios";
import { API_URL } from "../../consts";
import { useState, useEffect } from "react";
import { Place } from "./usePlaces";

type Hook = (id: string) => [Place | null, boolean, boolean];

const requestPlace = (id: string) => Axios.get(`${API_URL}/places/${id}`);

export const usePlace: Hook = (id: string) => {
  const [data, setData] = useState<Place | null>(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data }: { data: Place } = await requestPlace(id);
        setData(data);
      } catch (err) {
        setData(null);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [id]);

  return [data, isLoading, isError];
};
