import Axios from "axios";
import { API_URL } from "../../consts";
import { useState, useEffect } from "react";

interface BannerItem extends BannerItemDto {
  image: string;
}

interface BannerItemDto {
  id: string;
  link?: string;
}

type Hook = () => [BannerItem[], boolean, boolean];

const requestBanners = () => Axios.get(`${API_URL}/headers`);

const getBannerUrl = (id: string) => `${API_URL}/headers/${id}`;

export const useBannerUrls: Hook = () => {
  const [data, setData] = useState<BannerItem[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data }: { data: BannerItemDto[] } = await requestBanners();
        setData(data.map(item => ({ ...item, image: getBannerUrl(item.id) })));
      } catch (err) {
        setData([]);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return [data, isLoading, isError];
};
