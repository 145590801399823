import React from "react";

import { makeStyles } from "@material-ui/styles";
import { theme } from "../../Utils";
import { getCategoryImageUrl } from "../../hooks/category/useCategories";
import { Divider } from "antd";
import classNames from "classnames";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    textTransform: "uppercase",
    color: "black",
    margin: 0,
  },
  subtitle: {
    textTransform: "uppercase",
    color: theme.strongGrey,
    margin: 0,
  },
  categoryIcon: {
    marginTop: "1rem",
    maxWidth: "4rem",
    width: "15vmin",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    border: `2px ${theme.lightGrey} solid`,
    borderRadius: 5,
    padding: "2rem",
    marginTop: "2rem",
  },
  noBreak: {
    "& > * ": {
      display: "inline-block",
      whiteSpace: "pre-wrap",
    },
  },
});

interface InfoZoneProps {
  categoryName: string;
  categoryId: string;
  placeDescription: string;
  placeName: string;
  placeDeliveryMethod: string;
  placeDeliveryTime: string;
  className?: string;
}

function InfoZone({
  categoryName,
  categoryId,
  placeDescription,
  placeDeliveryTime,
  placeDeliveryMethod,
  placeName,
  className,
}: InfoZoneProps) {
  const classes = useStyles();
  return (
    <div className={classNames(classes.container, className)}>
      <h1 className={classes.title}>{placeName}</h1>
      <h2 className={classes.subtitle}>{categoryName}</h2>
      <img
        className={classes.categoryIcon}
        src={getCategoryImageUrl(categoryId)}
        alt={categoryName}
      />
      <div className={classes.textContainer}>
        <p>{placeDescription}</p>
        <p className={classes.noBreak}>
          <b>Forma de Entrega: </b>
          <span>{placeDeliveryMethod}</span>
          <Divider type="vertical" />
          <b>Tempo de Entrega: </b>
          {placeDeliveryTime}
        </p>
      </div>
    </div>
  );
}

export default InfoZone;
