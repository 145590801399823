import React from "react";

import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  contactRow: {
    display: "-webkit-flex",
    alignItems: "center",
    padding: "0.25rem 0",
    "& > *:first-child": {
      flex: 0,
      maxWidth: "1rem",
      marginRight: "0.5rem",
    },
    "& > *:last-child": {
      flex: 1,
      marginLeft: "0.5rem",
      marginBottom: 0,
      wordBreak: "break-word",
      whiteSpace: "pre-wrap",
    },
  },
});

interface ContactZoneProps {
  address: string;
  emails: string[];
  phoneNumbers: string[];
  urls: string[];
  className?: string;
}

function ContactZone({
  phoneNumbers,
  address,
  emails,
  urls,
  className,
}: ContactZoneProps) {
  const classes = useStyles();
  return (
    <div className={classNames(classes.container, className)}>
      <div className={classes.contactRow}>
        <img src={require("../../common_assets/morada.svg")} alt="morada" />
        <a
          href={`http://maps.google.com/?q=${address}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {address}
        </a>
      </div>
      {emails.map((email) => (
        <div key={email} className={classes.contactRow}>
          <img src={require("../../common_assets/email.svg")} alt="email" />
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      ))}
      {phoneNumbers.map((number) => (
        <div key={number} className={classes.contactRow}>
          <img
            src={require("../../common_assets/telefone.svg")}
            alt="telefone"
          />
          <a href={`tel:${number}`}>{number}</a>
        </div>
      ))}
      {urls.map((url) => (
        <div key={url} className={classes.contactRow}>
          <img src={require("../../common_assets/web.svg")} alt="telefone" />
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        </div>
      ))}
    </div>
  );
}

export default ContactZone;
