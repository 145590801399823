import React from "react";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  badge: {
    position: "relative",
    display: "-webkit-flex",
    maxHeight: "5rem",
    height: "15vmin",
    transition: "all 100ms",
    margin: "0.4rem 0.4rem",

    "& > .text-container": {
      transition: "all 200ms",
      width: "100%",
      opacity: 0,
      position: "absolute",
      display: "-webkit-flex",

      height: "100%",
      "&:hover": {
        opacity: 1,
        backgroundColor: "rgba(255, 255, 255, 0.75)",
      },
      "&:focus": {
        opacity: 1,
        backgroundColor: "rgba(255, 255, 255, 0.75)",
      },
      "& .text": {
        alignSelf: "center",
        textAlign: "center",
        margin: 0,
        pointerEvents: "none",
        wordBreak: "break-word",
      },
    },

    "& > img": {
      height: "15vmin",
      maxHeight: "5rem",
    },
  },
});

type FeatureBadgeVariant =
  | "acceptsCash"
  | "acceptsMB"
  | "acceptsMBWay"
  | "allowsCredit";

interface FeatureBadgeProps {
  variant: FeatureBadgeVariant;
}

const renderImage = (variant: FeatureBadgeVariant) => {
  switch (variant) {
    case "acceptsCash":
      return (
        <img
          src={require("../../common_assets/servico-dinheiro.svg")}
          alt="aceita dinheiro"
        />
      );
    case "acceptsMB":
      return (
        <img
          src={require("../../common_assets/servicos-multibanco.svg")}
          alt="aceita multibanco"
        />
      );
    case "acceptsMBWay":
      return (
        <img
          src={require("../../common_assets/servicos-mbway.svg")}
          alt="aceita mbway"
        />
      );
    case "allowsCredit":
      return (
        <img
          src={require("../../common_assets/servico-credito.svg")}
          alt="permite credito"
        />
      );
  }
};

const getText = (variant: FeatureBadgeVariant) => {
  switch (variant) {
    case "acceptsCash":
      return "Aceita Dinheiro";
    case "acceptsMB":
      return "Aceita Multibanco";
    case "acceptsMBWay":
      return "Aceita MBWay";
    case "allowsCredit":
      return "Permite Crédito";
  }
};

function FeatureBadge({ variant }: FeatureBadgeProps) {
  const classes = useStyles();
  return (
    <div
      className={classes.badge}
      onClick={(ev) => {
        ev.stopPropagation();
      }}
    >
      <div className="text-container">
        <p className="text">{getText(variant)}</p>
      </div>
      {renderImage(variant)}
    </div>
  );
}

export default React.memo(FeatureBadge);
