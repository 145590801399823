import React from "react";
import { makeStyles } from "@material-ui/styles";
import { GenericPageTransition } from "../../Utils";
import { motion } from "framer-motion";

const useStyles = makeStyles({
  container: {
    display: "-webkit-flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    flexGrow: 1
  },
  title: {
    textAlign: "center"
  }
});

function NoPlaces() {
  const classes = useStyles();

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
      className={classes.container}
    >
      <h1 className={classes.title}>
        Não encontrámos estabelecimentos nenhuns para a categoria / freguesia
      </h1>
    </motion.div>
  );
}

export default NoPlaces;
