import React from "react";
import { Place } from "../../hooks/places/usePlaces";
import { makeStyles } from "@material-ui/styles";
import PlaceItem from "./PlaceItem";
import { GenericPageTransition } from "../../Utils";
import { motion } from "framer-motion";

const useStyles = makeStyles({
  container: {
    display: "-webkit-flex",
    flex: 1,
    flexGrow: 1,
    flexDirection: "column"
  }
});
interface PlaceListProps {
  places: Place[];
}

function PlaceList({ places }: PlaceListProps) {
  const classes = useStyles();
  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
      className={classes.container}
    >
      {places.map(place => (
        <PlaceItem key={place.id} place={place} />
      ))}
    </motion.div>
  );
}

export default PlaceList;
