import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { usePlace } from "../../hooks/places/usePlace";
import { Loader } from "../../components/Loader";
import { Error } from "../../components/Error";
import PlaceInfoDesktop from "./PlaceInfoDesktop";
import { IsTablet } from "../../hooks/media-queries/mediaQueries";
import PlaceInfoMobile from "./PlaceInfoMobile";
import BackButton from "../../components/BackButton/BackButton";
import SigData from "../../common_assets/sig_data.json";

const locationPolis: Record<string, number[][] | undefined> = {
  "Freguesia de União de Freguesias de Abrunheira, Verride e Vila Nova da Barca": SigData.features.find(
    (f) =>
      f.properties.freguesia ===
      "União das freguesias de Abrunheira, Verride e Vila Nova da Barca"
  )?.geometry.coordinates,
  "Freguesia de União de Freguesias de Montemor-o-Velho e Gatões": SigData.features.find(
    (f) =>
      f.properties.freguesia ===
      "União das freguesias de Montemor-o-Velho e Gatões"
  )?.geometry.coordinates,
  Arazede: SigData.features.find((f) => f.properties.freguesia === "Arazede")
    ?.geometry.coordinates,
  Carapinheira: SigData.features.find(
    (f) => f.properties.freguesia === "Carapinheira"
  )?.geometry.coordinates,
  Ereira: SigData.features.find((f) => f.properties.freguesia === "Ereira")
    ?.geometry.coordinates,
  Liceia: SigData.features.find((f) => f.properties.freguesia === "Liceia")
    ?.geometry.coordinates,
  "Meãs do Campo": SigData.features.find(
    (f) => f.properties.freguesia === "Meãs do Campo"
  )?.geometry.coordinates,
  Pereira: SigData.features.find((f) => f.properties.freguesia === "Pereira")
    ?.geometry.coordinates,
  "Santo Varão": SigData.features.find(
    (f) => f.properties.freguesia === "Santo Varão"
  )?.geometry.coordinates,
  "Seixo de Gatões": SigData.features.find(
    (f) => f.properties.freguesia === "Seixo de Gatões"
  )?.geometry.coordinates,
  Tentúgal: SigData.features.find((f) => f.properties.freguesia === "Tentúgal")
    ?.geometry.coordinates,
};

export const getCentroid2 = function (arr: number[][]): [number, number] {
  var twoTimesSignedArea = 0;
  var cxTimes6SignedArea = 0;
  var cyTimes6SignedArea = 0;

  var length = arr.length;

  var x = function (i: number) {
    return arr[i % length][0];
  };
  var y = function (i: number) {
    return arr[i % length][1];
  };

  for (var i = 0; i < arr.length; i++) {
    var twoSA = x(i) * y(i + 1) - x(i + 1) * y(i);
    twoTimesSignedArea += twoSA;
    cxTimes6SignedArea += (x(i) + x(i + 1)) * twoSA;
    cyTimes6SignedArea += (y(i) + y(i + 1)) * twoSA;
  }
  var sixSignedArea = 3 * twoTimesSignedArea;
  return [
    cxTimes6SignedArea / sixSignedArea,
    cyTimes6SignedArea / sixSignedArea,
  ];
};

interface RouteParams {
  placeId: string;
}

function PlaceInfo() {
  const { placeId } = useParams<RouteParams>();
  const [place, isLoading, hasError] = usePlace(placeId);
  const isTablet = IsTablet();
  const history = useHistory();

  if (isLoading) return <Loader />;

  if (!place || hasError) return <Error />;

  return (
    <>
      <BackButton
        onClick={() => {
          history.goBack();
        }}
      />
      {isTablet ? (
        <PlaceInfoMobile
          place={place}
          poli={locationPolis[place.location.name]}
        />
      ) : (
        <PlaceInfoDesktop
          place={place}
          poli={locationPolis[place.location.name]}
        />
      )}
    </>
  );
}

export default PlaceInfo;
