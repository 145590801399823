import React, { useState, useEffect } from "react";
import { PlaceList } from "../../components/PlaceList";
import {
  useParams,
  useHistory,
  generatePath,
  useRouteMatch
} from "react-router-dom";
import { usePlaces, GetPlacesDto } from "../../hooks/places/usePlaces";
import { makeStyles } from "@material-ui/styles";
import { useLocations } from "../../hooks/locations/useLocations";
import { Select } from "antd";
import { BackButton } from "../../components/BackButton";
import NoPlaces from "./NoPlaces";
import { Loader } from "../../components/Loader";
import { motion } from "framer-motion";
import { GenericPageTransition } from "../../Utils";
import { Error } from "../../components/Error";
import { useCategories, Category } from "../../hooks/category/useCategories";

const useStyles = makeStyles({
  page: {
    display: "-webkit-flex",
    flexDirection: "column",
    flex: 1,
    flexGrow: 1,
    padding: "1rem 10%"
  },
  selectContainer: {
    display: "-webkit-flex",
    justifyContent: "center",
    marginBottom: "1rem 0"
  },
  selectLabel: {
    marginTop: "1rem",
    maxWidth: 400,
    flex: 1,
    textTransform: "uppercase"
  },
  select: {
    maxWidth: 400,
    flex: 1,
    marginBottom: "1rem"
  },
  breadcrumb: {
    textTransform: "uppercase",
    margin: 0
  }
});
interface RouteParams {
  locationFriendlyId?: string;
  categoryFriendlyId?: string;
}

function Places() {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch<RouteParams>();
  const params = useParams<RouteParams>();

  const [queryDto, setQueryDto] = useState<GetPlacesDto>({
    locationFriendlyId: params.locationFriendlyId,
    categoryFriendlyId:
      params.categoryFriendlyId === "geral"
        ? undefined
        : match.params.categoryFriendlyId
  });

  useEffect(() => {
    setQueryDto({
      locationFriendlyId: params.locationFriendlyId,
      categoryFriendlyId:
        !params.categoryFriendlyId || params.categoryFriendlyId === "geral"
          ? undefined
          : params.categoryFriendlyId
    });
  }, [params]);

  const [places, arePlacesLoading, placesHaveError] = usePlaces(queryDto);
  const [locations, areLocationsLoading, locationsHaveError] = useLocations();
  const [
    categories,
    areCategoriesLoading,
    categoriesHaveError
  ] = useCategories();

  const [categoryForDisplay, setCategoryForDisplay] = useState<
    Category | undefined
  >(undefined);

  useEffect(() => {
    setCategoryForDisplay(
      categories.find(cat => cat.friendlyId === params.categoryFriendlyId)
    );
  }, [categories, params.categoryFriendlyId]);

  if (!params.categoryFriendlyId)
    history.replace(
      generatePath(match.path, {
        categoryId: "geral",
        locationId: params.locationFriendlyId
      })
    );

  if (areLocationsLoading || arePlacesLoading || areCategoriesLoading) {
    return <Loader />;
  }

  if (placesHaveError || locationsHaveError || categoriesHaveError)
    return <Error />;

  return (
    <motion.div
      className={classes.page}
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
    >
      <BackButton
        onClick={() => {
          history.push("/");
        }}
      />
      <div className={classes.selectContainer}>
        <h3 className={classes.selectLabel}>Escolha a freguesia:</h3>
      </div>
      <div className={classes.selectContainer}>
        <Select
          size="large"
          className={classes.select}
          value={match.params.locationFriendlyId || "all"}
          onChange={value => {
            const path = generatePath(match.path, {
              categoryFriendlyId: match.params.categoryFriendlyId || "geral",
              locationFriendlyId: value === "all" ? undefined : value
            });
            history.push(path);
          }}
        >
          <Select.Option value="all">Todas</Select.Option>
          {locations.map(location => (
            <Select.Option key={location.id} value={location.friendlyId}>
              {location.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <p className={classes.breadcrumb}>
        Categoria:{" "}
        <span style={{ color: categoryForDisplay?.color }}>
          {categoryForDisplay?.name}
        </span>
      </p>
      {places.length === 0 ? <NoPlaces /> : <PlaceList places={places} />}
    </motion.div>
  );
}

export default Places;
