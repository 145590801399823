import React from "react";
import { Result, Button } from "antd";
import { ResultProps } from "antd/lib/result";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 1,
    flexGrow: 1,
    "& > .ant-result-icon": {
      marginTop: 0
    }
  }
});
interface ErrorProps
  extends Omit<ResultProps, "title" | "subTitle" | "status"> {
  title?: string;
  subTitle?: string;
  status?:
    | "404"
    | 403
    | 404
    | 500
    | "403"
    | "500"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
}

function Error({ title, status, subTitle, className }: ErrorProps) {
  if (!status) {
    status = "404";
  }
  if (!subTitle) {
    subTitle = "Algo correu mal, não quer tentar outra vez?";
  }

  if (!title) {
    title = "Ups";
  }
  const classes = useStyles();
  const history = useHistory();
  return (
    <Result
      className={classNames(classes.container, className)}
      status={status}
      title={title}
      subTitle={subTitle}
      extra={
        <Button onClick={() => history.replace("/")}>
          Voltar à página principal
        </Button>
      }
    />
  );
}

export default Error;
