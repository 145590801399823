import React from "react";
import { Header } from "../components/Header";
import { AnimatePresence } from "framer-motion";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { Home } from "./Home";
import { Footer } from "../components/Footer";
import { Places } from "./Places";
import { PlaceInfo } from "./PlaceInfo";
import { Join } from "./Join";

function MainRouter() {
  return (
    <Router>
      <Header />
      <AnimatePresence exitBeforeEnter>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route
            path="/places/:categoryFriendlyId/:locationFriendlyId?"
            component={Places}
          />
          <Route path="/aderir" component={Join} />
          <Route path="/place/:placeId" exact component={PlaceInfo} />
          <Redirect to="/" />
        </Switch>
      </AnimatePresence>
      <Footer />
    </Router>
  );
}

export default MainRouter;
