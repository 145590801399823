import React from "react";
import { makeStyles } from "@material-ui/styles";
import { PacmanLoader } from "react-spinners";
import { theme, GenericPageTransition } from "../../Utils";
import { motion } from "framer-motion";

const useStyles = makeStyles({
  container: {
    display: "-webkit-flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    flexGrow: 1
  }
});

function Loader() {
  const classes = useStyles();

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
      className={classes.container}
    >
      <PacmanLoader color={theme.strongGrey} />
    </motion.div>
  );
}

export default Loader;
