import { useCurrentSize } from "../useCurrentSize";
import { useState, useEffect } from "react";

export const IsTablet = () => {
  const { width } = useCurrentSize();
  const [isTablet, setIsTablet] = useState(width <= 768);

  useEffect(() => {
    setIsTablet(width <= 768);
  }, [width]);

  return isTablet;
};
