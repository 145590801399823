import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Carousel } from "antd";
import { useBannerUrls } from "../../hooks/banners/useBanners";
import { motion } from "framer-motion";
import { GenericPageTransition } from "../../Utils";
import { useHistory } from "react-router-dom";

export const headerHeight = 192;

const useStyles = makeStyles({
  container: {
    display: "-webkit-flex",
    flexDirection: "column",
    // height: headerHeight,
    // maxHeight: headerHeight,
    position: "relative",
    "& .ant-carousel": {
      width: "100%",
    },
  },
  carouselItem: {
    height: headerHeight,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  logoSection: {
    position: "absolute",
    height: "100%",
    width: headerHeight,
    zIndex: 100,
    backgroundColor: "rgba(255,255,255,0.75)",
    display: "-webkit-flex",
    justifyContent: "center",
    cursor: "pointer",
    alignItems: "center",
    "& > img": {
      maxHeight: "75%",
      maxWidth: "75%",
    },
  },
  bannerImage: {
    maxWidth: "100%",
  },
  "@media(max-width: 425px)": {
    logoSection: {
      position: "relative",
      maxHeight: 48,
      height: 48,
      width: "100%",
    },
    bannerImage: {
      maxHeight: "unset",
    },
  },
});
function Header() {
  const classes = useStyles();
  const [banners] = useBannerUrls();
  const history = useHistory();

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      variants={GenericPageTransition}
      className={classes.container}
    >
      <div
        className={classes.logoSection}
        onClick={() => {
          history.push("/");
        }}
      >
        <img src={require("../../common_assets/cmmvelho.png")} alt="logo" />
      </div>
      <Carousel autoplaySpeed={8000} autoplay>
        {banners.map((banner) =>
          banner.link ? (
            <a key={banner.id} href={banner.link}>
              <img
                src={banner.image}
                className={classes.bannerImage}
                alt="banner"
              />
            </a>
          ) : (
            <div key={banner.id}>
              <img
                src={banner.image}
                className={classes.bannerImage}
                alt="banner"
              />
            </div>
          )
        )}
      </Carousel>
    </motion.div>
  );
}

export default Header;
